<template>
  <div>
    <t-dropdown variant="smActions">
      <template
        v-slot:trigger="{
          mousedownHandler,
          focusHandler,
          blurHandler,
          keydownHandler,
        }"
      >
        <button
          class="text-oDark focus:outline-none"
          aria-label="Report Status Menu"
          aria-haspopup="true"
          @mousedown="mousedownHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keydown="keydownHandler"
        >
          <svg
            class="w-6 h-5 mt-1 text-oGray hover:text-oDark"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
            ></path>
          </svg>
        </button>
      </template>

      <template v-slot:default="{ hide }">
        <div @click="hide()" class="py-1 mt-px bg-white rounded-md shadow-md">
          <t-dropdown-item class="font-medium" @click="executeAction('status')">
            {{
              data.is_active === true
                ? $t('components.serviceAreaManagement.actions.deactivate')
                : $t('components.serviceAreaManagement.actions.activate')
            }}
          </t-dropdown-item>

          <t-dropdown-item variant="danger" @click="askConfirmation">
            {{ $t('components.serviceAreaManagement.actions.delete') }}
          </t-dropdown-item>
          <t-dropdown-item
            v-if="isMakeDefault"
            @click="executeAction('make-default')"
          >
            {{ $t('components.serviceAreaManagement.actions.makeDefault') }}
          </t-dropdown-item>
          <t-dropdown-item @click="handleExportKML">
            {{ $t('components.serviceAreaManagement.actions.exportToKML') }}
          </t-dropdown-item>
        </div>
      </template>
    </t-dropdown>

    <ConfirmFirst
      :id="`confirmFirst-${data.id}`"
      :key="`confirmFirst-${data.id}`"
      :isLoading="isLoading"
      :title="$t('components.serviceAreaManagement.actions.deleteArea')"
      :subtitle="
        $t('components.serviceAreaManagement.actions.actionCannotBeUndone')
      "
      :confirmButtonText="$t('components.serviceAreaManagement.actions.delete')"
      :cancelButtonText="$t('components.serviceAreaManagement.actions.cancel')"
      :confirmButtonVariant="`orange`"
      @confirm="executeAction('delete')"
      @cancel="handleCancellation"
    />
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'
export default {
  name: 'MoreActionsDropdown',
  components: {
    TDropdownItem: () => import('@/components/dropdown/TDropdownItem'),
    ConfirmFirst: () => import('@/components/modals/ConfirmFirst'),
  },
  props: {
    type: {
      type: String,
      required: false,
      default: 'serviceArea',
      validaotor(val) {
        return [
          'serviceArea',
          'parkingArea',
          'slowSpeedArea',
          'restrictedArea',
          'rentStationArea',
          'sunpodStationArea',
          'rewardZone',
        ].includes(val)
      },
    },
    data: {
      type: Object,
      required: false,
      default() {
        return {
          status: false,
        }
      },
    },
    isMakeDefault: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    isLoading: false,
  }),
  methods: {
    askConfirmation() {
      this.$modal.show(`confirmFirst-${this.data.id}`)
    },
    async executeAction(type) {
      console.log('executeAction', this.type, this.$config)
      if (type === 'status') {
        const endpoint = this.$config[this.type].api.update(this.data.id)

        const formData = new FormData()
        formData.append('is_active', !this.data.is_active)

        this.isLoading = true
        await this.$http
          .patch(endpoint, formData)
          .then((res) => {
            console.log('change', res)
            this.$emit('change', res.data)
          })
          .catch((err) => {
            console.log('err', err.response.data)
            this.$notify({
              type: `error`,
              group: `generic`,
              title: `Error`,
              text: err.response.data.message
                ? err.response.data.message
                : err.response.data,
            })
          })
          .finally(() => {
            this.isLoading = false
          })
      }

      if (type === 'make-default') {
        // const endpoint = this.$config[this.type].api.update(this.data.id)
        const endpoint = useEndpoints.geoFence.serviceArea.makeDefaults(
          this.data.id
        )

        this.isLoading = true
        await this.$http
          .patch(endpoint)
          .then((res) => {
            console.log('change', res)
            this.$store.dispatch('fsTable/fetchData')
            this.$notify({
              type: `success`,
              group: `generic`,
              title: `Success`,
              text: `Making Default has been Successful`,
            })
          })
          .catch((err) => {
            console.error(err)
            this.$notify({
              type: `success`,
              group: `generic`,
              title: `Error`,
              text: err.response.data.message
                ? err.response.data.message
                : err.response.data,
            })
          })
          .finally(() => {
            this.isLoading = false
          })
      }

      if (type === 'delete') {
        const endpoint = this.$config[this.type].api.delete(this.data.id)

        this.isLoading = true
        await this.$http
          .delete(endpoint)
          .then((res) => {
            console.log('delete', res.data)
            this.$emit('delete', res.data)
            this.$modal.hide(`confirmFirst-${this.data.id}`)
          })
          .catch((err) => {
            console.log('err', err.response.data)
            this.$notify({
              type: `error`,
              group: `generic`,
              title: `Error`,
              text: err.response.data.message
                ? err.response.data.message
                : err.response.data,
            })
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    handleCancellation() {
      console.log('cancelled')
    },
    handleExportKML() {
      this.$emit('exportKML')
    },
  },
}
</script>

<style lang="scss" scoped></style>
